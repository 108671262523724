import { Select } from "view-design";

const zhTW = {
  network: {
    tips: `目前不支援所選的網路！請選擇 Matic (Polygon) 網路！`,
    ev: `請檢測是否安裝 \nMetaMask,\nTrustWallet,imToken,TokenPocket\n等錢包中任意一款錢包軟體.`,
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `<h3><strong style="color:yellow">本DAPP在特定國家和地區不受法律保護，請用戶謹慎操作！</strong></h3>
    <br/>
    <p><strong>尊敬的用戶：</strong></p>
<br/>
    <p>感謝您對本DAPP的關注與支持。為了確保您的合法權益並瞭解相關法律風險，特此發布以下重要公告：</p><br/>

    <p>本DAPP涉及虛擬貨幣投資與交易，該類活動在部分國家和地區可能違反相關法律規定。根據當地法律法規及相關監管機構的政策要求，本DAPP無法在特定國家和地區合法運營，因此我們明確聲明：</p>
<br/>
    <h3><strong>1. 服務範圍的限制：</strong></h3><br/>
    <p style="color:yellow">本DAPP不在以下國家和地區提供虛擬貨幣投資與交易服務，也不提供任何形式的技術支持或售後服務：</p><br/>
    <p style="color:yellow">中國（包括中國大陸、香港特別行政區、澳門特別行政區及台灣地區）/
   印度/阿爾及利亞/孟加拉國/摩洛哥/埃及/尼泊爾/巴基斯坦/巴基斯坦/卡塔爾/伊拉克/突尼西亞/阿富汗/玻利維亞。</p>
   <br/> <strong>對於在上述國家和地區使用本DAPP進行虛擬貨幣投資與交易的用戶，我們建議您立即停止使用，以避免可能引發的法律風險。</strong>
<br/><br/>
    <h3><strong>2. 法律風險與投資風險提示：</strong></h3><br/>
    <p>在上述國家和地區使用本DAPP進行虛擬貨幣投資與交易的任何操作，均可能面臨嚴重的法律後果。本團隊不承擔因此產生的任何法律責任。此外，虛擬貨幣市場本身具有高度波動性和不可預測性，投資行為存在顯著風險。請您務必在充分理解並確認相關風險的基礎上，自行決定是否繼續使用本DAPP進行投資與交易。</p>
<br/>
    <h3><strong>3. 用戶確認：</strong></h3><br/>
    <p>若您繼續使用本DAPP進行虛擬貨幣投資與交易，即視為您已詳細閱讀並理解本公告內容，並確認您已知曉並願意承擔因此可能帶來的法律風險和投資風險。您所進行的任何操作，均視為自願承擔相關責任，運營方不承擔任何由此引發的後果。</p>
<br/>
    <h3><strong>4. 運營團隊聲明：</strong></h3><br/>
    <p>本DAPP由Bull Developer團隊研發與運營。我們一貫秉承合規經營的原則，並致力於為用戶提供優質的服務。然而，由於某些國家和地區的特殊法律環境，我們無法在這些區域內繼續提供虛擬貨幣相關服務。我們強烈建議所有用戶在使用本DAPP前，務必瞭解並遵守所在國家或地區的法律法規，避免可能的法律和投資風險。</p>
<br/>
    <p><strong>再次感謝您的理解與支持。如有任何疑問或需要進一步的幫助，您可以通過我們的官方網站與我們取得聯繫。</strong></p>
<br/>
    <p><strong>祝您一切順利！</strong></p>
<br/>
    <p><strong>運營方：Bull Developer團隊</strong></p>`,
    protocol: `本去中心化應用程式 (DApp) 存在不確定的投資風險。在使用之前，請務必仔細閱讀並理解風險提示和隱私政策！`,
    more: "詳情",
    media: "相關社交媒體",
    stake: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    withdraw: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    Reinvest: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    Getreward: "2023年11月6日全球開放，請關注Telegram、Twitter!",
    linkwallet: "连接钱包",
    notification: "即將上線Falcon燃燒加速功能！",
    flashloansOK:'啟動成功!',
    flashloansNo:'啟動失敗!',
    flashloansOnce:'恭喜您獲得1次體驗機會!',
    flashloansError:'抱歉，體驗機會已使用完畢!',
    falocnerror:'Falcon™ V2未獲准測試資格',
    falocninfo:'Falcon™ V2 針對3級獲批的玩家！現處於公測階段!',
    falconv4:'Falcon™V4套利集群正在內測中（28000次/日）！'
  },
  leavel: {
    0:"葦鷲",
    1: "鳶鷹",
    2: "貓頭鷹",
    3: "紅隼",
    4: "雕鷹",
    5: "黑鷹",
    6: "海雕",
    7: "遊隼",
    8: "金雕",
    9: "神鷹",    
    10: "FALCON",    
  },
  top: {
    Account: "帳戶",
  },
  swap:{
    Swap:"兌換",
    paynameA:"付款",
    paynameB:"收款",
    Approve:"授權",
    launchSwap:"啟動交換",
    recommend:"推薦",
    swapError:"兌換發生錯誤",
    swapNetwork:"網絡發生錯誤",
  },
  stake: {
    Balance: "錢包",
    Total: "己經存入",
    Appoove: "授權",
    Stake: "存入",
    Appooveing: "授權中",
    AuthorSuccessful: "授權成功",
    AuthorFailed: "授權失敗",
    Stakeing: "存入中",
    StakeSuccessful: "存入成功",
    StakeFailed: "存入失敗",
    StakeInfo: "不能小於:",
    Contract:"套利合約",
    Appoove2:"自動",
    Activeaccount:"激活帳戶",
    active:"激活"
  },
  menu: {
    Home: "首頁",
    Language: "語言",
    Document: "文檔",
    Contract: "合約",
    Watch: "觀察",
    Coming: "即將發佈",
    EvDao: "社區治理",
    Flashloan: "啟動閃電貸",
    Developer:"開發者文檔",
    Launch:"啟動",
    xNumber:"剩餘",
    Falcon:"Falcon™",
    xTips:"自動處理",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3 Pro",
    LogiV4:"Falcon™ V4",
    swap:"閃兌",
    Market:"市場",
    LogiV2pro:"Falcon™ V2 Pro",
    LogiV3tips:"Falcon™ V3全球公測開始（日請求1000~7000次），需授權相應的套利合約！",
    Appoove2:"自動",
    Ido:"Falcon認購",
    myIdo:"我的認購",
    interest:"收益",
  },
  ido:{
    select: "選擇社區",
    flashmonkey: "閃電猴",
    flashdragon: "閃電龍",
    fashFalcon: "Falcon",
    powermonger: "寶龍",
    fashfalcon2: "FalconV2",
    other: "其它",
    Getmax: "獲取最大認購額度",
    nowbuy: "立即認購",
    mynumber: "我的認購總額",
    myrelease: "已釋放",
    waitrelease: "待釋放",
    get: "提取",
    FalconF: "閃電鷹F",
    FalconA: "閃電鷹A",
    organization: "機構",
    endTimes:'第一輪倒計時',
    getmax:'獲取最大額度',
    Authorization:"授權",
    Subscribe:"立即認購",
    okSubscribe:"已認購",
    message:"節點錯誤，請更換節點",
    end1:"第一輪已結束",
    needStart:"距離開始",
    getscusse:"獲取成功",
    geterror:"獲取失敗"

  },
  Withdraw: {
    Withdraw: "提取",
    Withdrawable: "我的錢包",
    Earnings: "個人收益",
    Brokerage: "生態收益",
    Reinvest: "再資",
    Getreward: "獲取獎勵",
    Processing: "處理中",
    unsuccessfule: "處理失敗!",
    successful: "處理成功！",
    WithdrawInfo1: "請輸入提取數量",
    WithdrawInfo2: "數量不足",
    burning:"FalconBurn",
    noBurn:"暫未加速",
    addBurn:"增值收益",
    addBurnCost:"增値成本",
    BurnCount:"燃燒帳戶",
    BurnSpeed:" 燃燒加速",
    needpay:"需要支付",
    startBurn:"启动加速",
    Burning:"加速中"
  },
  Team: {
    innver: "邀請",
    Teamstaked: "生態",
    leavel: "榮譽",
    Team: "生態",
    Bind: "綁定關係",
    info: "系統暫停綁定，請等待開放！",
    Binding: "綁定中",
    BindingSuccessful: "綁定成功",
    BindingFailed: "綁定失敗",
    Bindinfo: "请输入邀请人钱包地址",
  },
  Share: {
    Invitelink: "邀請鏈接",
    Intips: "Dapp邀請目前不可用。請等待最新社區更新。",
    copyTitle: "邀請鏈接",
    copyText: "邀請鏈接複製成功！",
    copyCommnad: "複製連結",
  },
  Contract: {
    contractaddress: "合約地址",
    Copylink: "Polygonscan",
    CopylinkText: "前往polygonscan",
  },
  Wathch: {
    watchonlywallet: "僅觀看錢包",
    addwallet: "添加觀察錢包",
    add: "添加",
    Leavel: "榮譽",
    share: "分享",
    fnOpen: "暫未邀請此錢包參與此功能！",
    nullString: "請輸入錢包地址！",
    addok:"添加成功",
    addError:"添加失败",
    addisExists:"此地址己存在",
    addlist:"觀察列表",
    
  },
};
export default zhTW;
