const vi = {
  network: {
    tips: `Mạng lựa chọn hiện không được hỗ trợ!
      Vui lòng chọn mạng Matic (Polygon)!`,
    ev: "Vui lòng kiểm tra liệu bạn đã cài đặt phần mềm ví như MetaMask, Trust Wallet, imToken, hay TokenPocket chưa.",
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Tối đa",
    bolck: "Đồng tiền ổn định Dai",
    law: `<h3><strong style="color:yellow">DAPP này không được bảo vệ hợp pháp tại một số quốc gia và khu vực. Vui lòng sử dụng cẩn thận!</strong></h3>
    <br/>
    <p><strong>Kính gửi người dùng:</strong></p>
<br/>
    <p>Cảm ơn bạn đã quan tâm và ủng hộ DAPP này. Để đảm bảo quyền lợi hợp pháp của bạn và giúp bạn hiểu rõ các rủi ro pháp lý liên quan, chúng tôi xin đưa ra thông báo quan trọng sau:</p><br/>

    <p>DAPP này liên quan đến việc đầu tư và giao dịch tiền điện tử, có thể vi phạm pháp luật tại một số quốc gia và khu vực. Theo luật pháp địa phương, quy định và chính sách của các cơ quan quản lý, DAPP này không thể hoạt động hợp pháp tại một số quốc gia và khu vực. Do đó, chúng tôi tuyên bố như sau:</p>
<br/>
    <h3><strong>1. Hạn chế phạm vi dịch vụ:</strong></h3><br/>
    <p style="color:yellow">DAPP này không cung cấp dịch vụ đầu tư và giao dịch tiền điện tử, cũng như không cung cấp bất kỳ hỗ trợ kỹ thuật hoặc dịch vụ sau bán hàng nào tại các quốc gia và khu vực sau:</p><br/>
    <p style="color:yellow">Trung Quốc (bao gồm Trung Quốc Đại lục, Khu Hành chính Đặc biệt Hồng Kông, Khu Hành chính Đặc biệt Macau và Đài Loan), Ấn Độ, Algeria, Bangladesh, Maroc, Ai Cập, Nepal, Pakistan, Qatar, Iraq, Tunisia, Afghanistan, Bolivia.</p>
   <br/> <strong>Chúng tôi khuyên người dùng tại các quốc gia và khu vực nêu trên ngừng ngay lập tức việc sử dụng DAPP này để tránh các rủi ro pháp lý có thể phát sinh.</strong>
<br/><br/>
    <h3><strong>2. Cảnh báo rủi ro pháp lý và đầu tư:</strong></h3><br/>
    <p>Bất kỳ hoạt động sử dụng DAPP này cho việc đầu tư và giao dịch tiền điện tử tại các quốc gia và khu vực nêu trên đều có thể dẫn đến hậu quả pháp lý nghiêm trọng. Đội ngũ của chúng tôi không chịu trách nhiệm pháp lý về những hành động như vậy. Ngoài ra, thị trường tiền điện tử vốn dĩ rất biến động và khó lường, và các hoạt động đầu tư tiềm ẩn rủi ro đáng kể. Vui lòng đảm bảo rằng bạn hiểu rõ và xác nhận đầy đủ các rủi ro liên quan trước khi quyết định có tiếp tục sử dụng DAPP này để đầu tư và giao dịch hay không.</p>
<br/>
    <h3><strong>3. Xác nhận của người dùng:</strong></h3><br/>
    <p>Nếu bạn tiếp tục sử dụng DAPP này để đầu tư và giao dịch tiền điện tử, điều đó đồng nghĩa với việc bạn đã đọc kỹ và hiểu rõ nội dung của thông báo này, và bạn chấp nhận các rủi ro pháp lý và đầu tư có thể phát sinh. Mọi hành động của bạn sẽ được coi là bạn tự nguyện chịu trách nhiệm về các rủi ro liên quan, và đội ngũ điều hành không chịu trách nhiệm về bất kỳ hậu quả nào có thể xảy ra.</p>
<br/>
    <h3><strong>4. Tuyên bố từ đội ngũ điều hành:</strong></h3><br/>
    <p>DAPP này được phát triển và điều hành bởi đội ngũ Bull Developer. Chúng tôi luôn tuân thủ các nguyên tắc hoạt động hợp pháp và cam kết cung cấp dịch vụ chất lượng cao cho người dùng. Tuy nhiên, do môi trường pháp lý đặc thù ở một số quốc gia và khu vực, chúng tôi không thể tiếp tục cung cấp các dịch vụ liên quan đến tiền điện tử tại các khu vực này. Chúng tôi khuyến nghị mạnh mẽ rằng tất cả người dùng nên tìm hiểu và tuân thủ luật pháp và quy định của quốc gia hoặc khu vực của mình trước khi sử dụng DAPP này, để tránh các rủi ro pháp lý và đầu tư.</p>
<br/>
    <p><strong>Chúng tôi xin cảm ơn một lần nữa về sự hiểu biết và ủng hộ của bạn. Nếu bạn có bất kỳ câu hỏi nào hoặc cần hỗ trợ thêm, vui lòng liên hệ với chúng tôi qua trang web chính thức.</strong></p>
<br/>
    <p><strong>Trân trọng!</strong></p>
<br/>
    <p><strong>Đội ngũ Bull Developer</strong></p>`,
    protocol: `Ứng dụng phân quyền (DApp) này mang rủi ro đầu tư không chắc chắn. Trước khi sử dụng, hãy đảm bảo bạn đã đọc và hiểu rõ cảnh báo rủi ro và chính sách bảo mật.`,
    more: "Thêm",
    media: "Truyền thông xã hội liên quan",
    stake:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    withdraw:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    Reinvest:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    Getreward:
      "Mở cửa toàn cầu vào ngày 6 tháng 11 năm 2023, hãy theo dõi chúng tôi trên Telegram và Twitter!",
    linkwallet: "Kết nối ví",
    notification: "Tính năng đốt cháy tăng tốc Falcon sắp ra mắt!",
    flashloansOK:'Khởi động thành công!',
    flashloansNo:'Khởi động thất bại!',
    flashloansOnce:'Chúc mừng bạn đã nhận được 1 cơ hội trải nghiệm!',
    flashloansError:'Xin lỗi, cơ hội trải nghiệm đã được sử dụng hết!',
    falocnerror:'Falcon™ V2 Không được cấp quyền kiểm tra!',
    falocninfo:'Falcon™ V2 dành cho người chơi được phê duyệt ở cấp độ V3! Hiện đang trong giai đoạn thử nghiệm công khai!',
    falconv4:'Cụm chênh lệch giá Falcon™V4 đang trong giai đoạn thử nghiệm nội bộ (28000 lần/ngày)!'
  },
  leavel: {
    0:"Diều hâu đồng cỏ",
    1: "Diều hâu",
    2: "Cú mèo",
    3: "Diều hâu đỏ",
    4: "Đại bàng",
    5: "Đại bàng đen",
    6: "Đại bàng biển",
    7: "Chim cắt",
    8: "Đại bàng vàng",
    9: "Đại bàng thần",
    10: "FALCON",
  },
  top: {
    Account: "Tài khoản",
  },
  swap:{
    Swap:"Đổi",
    paynameA:"Thanh toán",
    paynameB:"Nhận tiền",
    Approve:"Ủy quyền",
    launchSwap:"Khởi động trao đổi",
    recommend:"Đề xuất",
    swapError:"Lỗi đổi",
    swapNetwork:"Lỗi mạng",
  },
  stake: {
    Balance: "Số dư của bạn",
    Total: "Tổng cược",
    Appoove: "Chấp nhận",
    Stake: "Cược",
    Appooveing: "Đang ủy quyền",
    AuthorSuccessful: "Ủy quyền thành công",
    AuthorFailed: "Ủy quyền thất bại",
    Stakeing: "Đang gửi tiền",
    StakeSuccessful: "Gửi tiền thành công",
    StakeFailed: "Gửi tiền thất bại",
    StakeInfo: "Không thể Nhỏ hơn:",
    Activeaccount:"Kích hoạt tài khoản",
    active:"Kích hoạt​"
  },
  menu: {
    Home: "Trang chủ",
    Language: "Ngôn ngữ",
    Document: "Sách Trắng",
    Contract: "Hợp đồng",
    Falcon:"Falcon™",
    Watch: "Theo dõi",
    Coming: "Sắp ra mắt",
    EvDao: "BULLDAO",
    Flashloan: "Flashloan",
    Developer:"Developer Docs",
    Launch:"Launch",
    xNumber:"Còn lại",
    xTips:"Xử lý tự động",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"Falcon™ V3 bắt đầu thử nghiệm công cộng toàn cầu (yêu cầu hàng ngày 1000~7000), cần phải có sự ủy quyền cho hợp đồng đầu cơ tương ứng!",
    Appoove2:"Auto",
        Ido:"Falcon IDO",
    myIdo:"My Falcon",
    interest:"Lợi nhuận",
  },
  ido:{
    select: "Select Community",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"

  },
  Withdraw: {
    Withdraw: "Rút tiền",
    Withdrawable: "Có thể rút",
    Earnings: "Lợi nhuận",
    Brokerage: "Số tiền môi giới",
    Reinvest: "Tái đầu tư",
    Getreward: "Nhận phần thưởng",
    Processing: "Đang xử lý",
    unsuccessfule: "Không thành công",
    successful: "Thành công!",
    burning:"FalconBurn",
    noBurn: "Chưa tăng tốc",
    addBurn: "Lợi nhuận tăng thêm",
    addBurnCost: "Chi phí tăng thêm",
    BurnCount: "Tài khoản đốt",
    BurnSpeed: "Tăng tốc đốt",
    needpay: "Cần thanh toán",
    startBurn: "Bắt đầu tăng tốc",
    Burning: "Đang tăng tốc"
  },
  Team: {
    innver: "Nội tại",
    Teamstaked: "Đội cược",
    leavel: "Cấp độ",
    Team: "Đội",
    Bind: "Gắn kết mối quan hệ",
    info: "Hệ thống tạm thời tạm ngừng kết nối, vui lòng đợi để mở lại.",
    Binding: "Đang Kết nối",
    BindingSuccessful: "Kết nối Thành công",
    BindingFailed: "Kết nối Thất bại",
    Bindinfo: "Vui lòng nhập địa chỉ ví của người mời",
  },
  Share: {
    Invitelink: "Liên kết mời",
    Intips: `Lời mời Dapp hiện không khả dụng. 
              Vui lòng chờ cập nhật mới nhất từ cộng đồng.`,
    copyTitle: "Liên kết mời",
    copyText: "Liên kết mời đã được sao chép thành công!",
    copyCommnad: "Sao chép liên kết",
  },
  Contract: {
    contractaddress: "Địa chỉ hợp đồng",
    Copylink: "Polygonscan",
    CopylinkText: "Chuyển đến polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Ví chỉ xem",
    addwallet: "Thêm ví",
    add: "Thêm",
    Leavel: "Cấp độ",
    share: "Chia sẻ",
    fnOpen: "Ví này chưa được mời tham gia tính năng này!",
    nullString: "Vui lòng nhập địa chỉ ví!！",
    addok:"Thêm thành công",
    addError:"Thêm thất bại",
    addisExists:"Địa chỉ này đã tồn tại",
    addlist:"Danh sách theo dõi",
  },
};
export default vi;
