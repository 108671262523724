const ja = {
  network: {
    tips: "選択されたネットワークは現在サポートされていません！Matic（Polygon）ネットワークを選択してください！",
    ev: `MetaMask、Trust Wallet、imToken、TokenPocketなどのウォレットソフトウェアのいずれかをインストールしているか確認してください。`,
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `<h3><strong style="color:yellow">このDAPPは特定の国や地域では法的保護を受けていません。ご利用には十分ご注意ください！</strong></h3>
    <br/>
    <p><strong>親愛なるユーザーの皆様：</strong></p>
<br/>
    <p>このDAPPに関心を寄せていただき、誠にありがとうございます。ユーザーの法的権利を保護し、関連する法的リスクを理解していただくために、以下の重要な告知を発表します。</p><br/>

    <p>このDAPPは暗号通貨の投資および取引に関連しており、一部の国や地域では法律に違反する可能性があります。現地の法令や規制当局の方針に基づき、このDAPPは特定の国や地域で合法的に運営することができないため、以下の内容を明確に告知します。</p>
<br/>
    <h3><strong>1. サービス提供範囲の制限：</strong></h3><br/>
    <p style="color:yellow">このDAPPは、以下の国や地域では暗号通貨の投資や取引サービスを提供しておらず、技術サポートやアフターサービスも行っていません：</p><br/>
    <p style="color:yellow">中国（中国本土、香港特別行政区、マカオ特別行政区、台湾を含む）/
   インド/アルジェリア/バングラデシュ/モロッコ/エジプト/ネパール/パキスタン/カタール/イラク/チュニジア/アフガニスタン/ボリビア。</p>
   <br/> <strong>上記の国や地域でこのDAPPを利用して暗号通貨の投資や取引を行っているユーザーの皆様には、直ちに使用を中止することをお勧めします。法的リスクを避けるためです。</strong>
<br/><br/>
    <h3><strong>2. 法的リスクと投資リスクの警告：</strong></h3><br/>
    <p>上記の国や地域でこのDAPPを使用して暗号通貨の投資や取引を行うと、重大な法的結果を招く可能性があります。当チームは、そのような行為によって発生するいかなる法的責任も負いません。また、暗号通貨市場は非常に変動性が高く、予測不可能であり、投資には顕著なリスクがあります。このDAPPを利用して投資や取引を継続するかどうかを決定する前に、関連するリスクを十分に理解し、確認するようにしてください。</p>
<br/>
    <h3><strong>3. ユーザーの確認：</strong></h3><br/>
    <p>このDAPPを引き続き使用して暗号通貨の投資や取引を行う場合、この告知の内容を詳細に読み、理解したものとみなし、発生する可能性のある法的リスクと投資リスクを認識し、引き受けることに同意したと見なされます。あなたが行った行動はすべて、関連する責任を自発的に引き受けたものと見なされ、運営チームはそれに伴ういかなる結果にも責任を負いません。</p>
<br/>
    <h3><strong>4. 運営チームからの声明：</strong></h3><br/>
    <p>このDAPPは、Bull Developerチームによって開発および運営されています。私たちは常にコンプライアンスの原則を遵守しており、ユーザーに高品質のサービスを提供することを目指しています。しかし、一部の国や地域の特殊な法的環境により、これらの地域で暗号通貨に関連するサービスを提供し続けることができません。すべてのユーザーに、このDAPPを使用する前に、居住している国または地域の法律や規制を理解し、遵守することを強くお勧めします。法的および投資リスクを回避するためです。</p>
<br/>
    <p><strong>改めてご理解とご支援に感謝申し上げます。ご質問や追加のサポートが必要な場合は、当社の公式ウェブサイトを通じてご連絡ください。</strong></p>
<br/>
    <p><strong>どうぞよろしくお願い申し上げます！</strong></p>
<br/>
    <p><strong>Bull Developerチーム</strong></p>`,
    protocol: `この分散アプリケーション（DApp）には不確かな投資リスクが潜んでいます。使用する前に、リスク警告とプライバシーポリシーを必ず注意深く読んで理解してください。`,
    more: "詳細",
    media: "関連ソーシャルメディア",
    stake:
      "2023年11月6日に全世界公開、TelegramとTwitterをフォローしてください！",
    withdraw:
      "2023年11月6日に全世界公開、TelegramとTwitterをフォローしてください！",
    Reinvest:
      "2023年11月6日に全世界公開、TelegramとTwitterをフォローしてください！",
    Getreward:
      "2023年11月6日に全世界公開、TelegramとTwitterをフォローしてください！",
    linkwallet: "ウォレットに接続",
    notification: "Falconの燃焼加速機能がまもなくリリースされます！",
    flashloansOK:'起動成功!',
    flashloansNo:'起動失敗!',
    flashloansOnce:'体験の機会を1回得たことをお祝いします!',
    flashloansError:'申し訳ありませんが、体験の機会は既に使い切られました!',
    falocnerror:'Falcon™ V2 テスト資格が承認されていません！',
    falocninfo:'Falcon™ V2 レベルの承認を受けたプレイヤー向け！現在はパブリックテスト段階です！!',
    falconv4:'Falcon™V4アービトラージクラスタがベータテスト中（1日28000回）！'
  },
  leavel: {
    0:"オオヨシゴイ",
    1: "トビ",
    2: "フクロウ",
    3: "ハイイロチュウヒ",
    4: "ワシ",
    5: "クロワシ",
    6: "オオワシ",
    7: "ハヤブサ",
    8: "イヌワシ",
    9: "神鷲",
    10: "FALCON",
  },
  top: {
    Account: "アカウント",
  },
  swap:{
    Swap:"交換",
    paynameA:"支払い ",
    paynameB:"受取",
    Approve:"認可",
    launchSwap:"交換を開始する",
    recommend:"推薦",
    swapError:"交換エラー",
    swapNetwork:"ネットワークエラー",
  },
  stake: {
    Balance: "あなたの残高",
    Total: "総ステーク",
    Appoove: "承認",
    Stake: "ステーク",
    Appooveing: "承認中",
    AuthorSuccessful: "承認成功",
    AuthorFailed: "承認失敗",
    Stakeing: "預金中",
    StakeSuccessful: "入金成功",
    StakeFailed: "入金失敗",
    StakeInfo: "以下になることはできません:",
    Activeaccount:"Activate account",
    active:"Activate"
  },
  menu: {
    Home: "ホーム",
    Language: "言語",
    Document: "ホワイトペーパー",
    Contract: "契約",
    Watch: "ウォッチ",
    Falcon:"Falcon™",
    Coming: "近日公開",
    EvDao: "BULLDAO",
    Flashloan: "起動Flashloan",
    Developer:"開発者ドキュメント",
    Launch:"起動",
    xNumber:"残り",
    xTips:"自動処理",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"Falcon™ V3のグローバル公開テストが始まりました（1日のリクエスト1000〜7000回）、該当するアービトラージ契約の承認が必要です！",
    Appoove2:"自動",
    Ido:"Falcon認購",
    myIdo:"私のFalcon",
    interest:"収益",

  },
  Withdraw: {
    Withdraw: "引き出す",
    Withdrawable: "引き出し可能",
    Earnings: "収益",
    Brokerage: "ブローカー額",
    Reinvest: "再投資",
    Getreward: "報酬を得る",
    Processing: "処理中",
    unsuccessfule: "失敗",
    successful: "成功！",
    WithdrawInfo1: "引き出し量を入力してください",
    WithdrawInfo2: "数量が不足しています",
    burning:"FalconBurn",
    noBurn:"No Burn",
    addBurn:"増加収益",
    addBurnCost:"増加コスト",
    BurnCount:"燃焼アカウント",
    BurnSpeed:"燃焼加速",
    needpay:"支払いが必要",
    startBurn:"加速開始",
    Burning:"加速中"
  },
  ido:{
    select: "コミュニティを選択",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "その他",
    Getmax: "最大購読額を取得",
    nowbuy: "今すぐ購読",
    mynumber: "私の購読合計",
    myrelease: "リリース済み",
    waitrelease: "リリース待ち",
    get: "引き出す",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "機関",
    endTimes:"第1ラウンドのカウントダウン",
    getmax:"最大購読額を取得",
    Authorization:"承認",
    Subscribe:"今すぐ購読",
    okSubscribe:"購読済み",
    message:"ノードエラー、ノードを変更してください",
    end1:"第1ラウンド終了",
    needStart:"開始までのカウントダウン",
    getscusse:"取得成功",
    geterror:"取得失敗"

  },
  Team: {
    innver: "Innver",
    Teamstaked: "チームのステーク",
    leavel: "レベル",
    Team: "チーム",
    Bind: "関係をバインド",
    info: "システムは一時的にバインディングを停止しています。オープンを待ってください",
    Binding: "バインド中",
    BindingSuccessful: "バインド成功",
    BindingFailed: "バインド失敗",
    Bindinfo: "招待者のウォレットアドレスを入力してください",
  },
  Share: {
    Invitelink: "招待リンク",
    Intips:
      "現在、Dappの招待状は利用できません。最新のコミュニティアップデートをお待ちください。",
    copyTitle: "招待リンク",
    copyText: "招待リンクのコピーに成功しました！",
    copyCommnad: "リンクをコピー",
  },
  Contract: {
    contractaddress: "契約アドレス",
    Copylink: "Polygonscan",
    CopylinkText: "polygonscanへ進む!",
  },
  Wathch: {
    watchonlywallet: "ウォッチオンリーウォレット",
    addwallet: "追加",
    add: "追加",
    Leavel: "レベル",
    share: "共有",
    fnOpen: "このウォレットは、この機能への参加がまだ招待されていません！", 
    nullString: "ウォレットアドレスを入力してください",
    addok:"追加成功",
    addError:"追加失敗",
    addisExists:"このアドレスは既に存在しています",
    addlist:"ウォッチリスト",
  },
};
export default ja;
