<template>
  <div class="stakewait">
    <div v-show="show == 1">
      <!-- <div class="marquee" style="margin-bottom: 15px;">
      <Marquee :duration="15">{{ $t("network.notification") }}</Marquee>
    </div> -->

      <div class="top_button">
        <div class="coinInfomation" @click="isShown2 = true">
          <div class="coinImg"><img :src="swap_aimg" class="dailogo" /></div>
          <div class="coinInfo">{{ swap_Aname }} Stablecoin</div>
          <img :src="swap_arrow" class="swaparrow" />
        </div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)" />

      <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("Withdraw.Withdrawable") }}:</div>

        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" v-if="swap_Aname != 'DAI'">
          {{ formattedDaiOwnMoney2 }}<img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" v-else>
          {{ formattedDaiOwnMoney }}<img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
      </div>
      <div class="inputInfo" v-if="swap_Aname != 'DAI'">
        <input v-model.number="daiValue" type="number" class="inputStyle" />
        <button @click="daiValue = formattedDaiOwnMoney2" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button>
      </div>
      <div class="inputInfo" v-else>
        <input v-model.number="daiValue" type="number" class="inputStyle" />
        <button @click="daiValue = formattedDaiOwnMoney2" class="tbnStyle ripple">
          {{ $t("network.all") }}
        </button>
      </div>

      <div class="WithDrawBtn">

        <button class="enabled-class " disabled v-if="swap_Aname == 'DAI'">
          {{ $t("Withdraw.Withdraw") }}
        </button>
        <button class="tbnStyle2 ripple" @click="withdraw" v-else-if="typeextract == 0">
          {{ $t("Withdraw.Withdraw") }}
        </button>
        <button class="tbnStyle2 ripple" v-else>
          {{ $t("Withdraw.Processing") }}
        </button>

        <button class="tbnStyle2" v-if="burnAmount <= 0 || burnUsersvalue == burnGetMyUserBurnInterestvalue1"
          @click="show = 2">
          {{ $t("Withdraw.noBurn") }}

        </button>
        <button class="tbnStyle2" v-else @click="show = 2">
          {{ $t("Withdraw.Burning") }}

        </button>
      </div>

      <div class="StakeInfo">
        <div class="StakeResult">{{ $t("Withdraw.Earnings") }}:</div>
        <div class="StakeMoney" style="display: flex;align-items: center;text-align: center;">
          {{ formattedShareMoney }}<img class="daimi" :src="swap_usdt" style="height: 20px;width: 20px;"
            v-if="swap_Aname == 'DAI'" />
          <img v-else class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
      </div>
      <div class="StakeInfo">
        <div class="StakeResult">{{ $t("Withdraw.Brokerage") }}:</div>
        <div class="StakeMoney" style="display: flex;align-items: center;text-align: center;"
          v-if="swap_Aname == 'DAI'">
          {{ formattedTemeMoney }}<img class="daimi" :src="swap_usdt" style="height: 20px;width: 20px;" />

        </div>
        <div class="StakeMoney" style="display: flex;align-items: center;text-align: center;" v-else>
          {{ formattedTemeMoney2 }} <img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>


      </div>
      <div class="StakeInfo">
        <div class="StakeResult">{{ $t("Withdraw.addBurn") }}:</div>
        <div class="StakeMoney" style="display: flex;align-items: center;text-align: center;">
          <div class="tbnStyle2_div" v-if="burnLevel > 0">
            {{ burnLevel }}x
          </div>
          {{ formattedDaiOwnMoney4 }}
          <img class="daimi" :src="swap_usdt" style="height: 20px;width: 20px;" v-if="swap_Aname == 'DAI'" />
          <img v-else class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
      </div>

      <div class="StakeInfo">
        <div class="StakeResult">{{ $t("Withdraw.addBurnCost") }}:</div>
        <div class="StakeMoney" style="display: flex;align-items: center;text-align: center;">
          {{ formattedDaiOwnMoney5 }}
          <img class="daimi" :src="swap_usdt" style="height: 20px;width: 20px;" v-if="swap_Aname == 'DAI'" />
          <img v-else class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
      </div>


      <div class="StakeAuthor">
        <div class="Author">
          <!-- <button class="StakeBtn ripple" @click="withdraw2" v-if="typeReinvestment == 0"></button> -->
          <button class="StakeBtn ripple" @click="withdraw2" v-if="typeReinvestment == 0">
            {{ $t("Withdraw.Reinvest") }}
          </button>
          <button class="StakeBtn ripple" v-else>
            {{ $t("Withdraw.Processing") }}
          </button>


        </div>
        <div class="Stakeing">
          <button class="enabled-class2" disabled v-if="swap_Aname == 'DAI'">
            {{ $t("Withdraw.Getreward") }}
          </button>
          <button class="StakeBtn ripple" @click="withdraw3" v-else-if="typeReceive == 0">
            {{ $t("Withdraw.Getreward") }}
          </button>
          <button class="StakeBtn ripple" v-else>
            {{ $t("Withdraw.Processing") }}
          </button>
        </div>
      </div>
    </div>
    <div class="" v-show="show == 2">
      <div class="fanhui">
        <img @click="show = 1" src="../assets/img/fh.png" alt="">
      </div>
      <div class="top_button">
        <div class="coinInfomation" style="background-color: rgba(142, 200, 243, 0.0);">
          <div class="coinImg"><img :src="swap_speed" class="dailogo" /></div>
          <div class="coinInfo" style="color: #fff;">{{ $t("Withdraw.BurnSpeed") }}</div>

        </div>

      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)" />

      <!-- <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("Withdraw.Withdrawable") }}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" v-if="swap_Aname != 'DAI'">
          {{ formattedDaiOwnMoney2 }}<img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;" v-else>
          {{ formattedDaiOwnMoney }}<img class="daimi" :src="swap_aimg" style="height: 20px;width: 20px;" />
        </div>
      </div> -->

      <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("Withdraw.Withdrawable") }}:</div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;">
          {{ formattedDaiOwnMoney3 }}<img class="daimi" :src="swap_falcon" style="height: 20px;width: 20px;" />
        </div>
      </div>

      <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("Withdraw.BurnCount") }}:</div>
        <div class="ownTips"></div>
        <div class="ownMoney" style="display: flex;align-items: center;text-align: center;">
          {{ burnFalconAmount }}<img class="daimi" :src="swap_falcon" style="height: 20px;width: 20px;" />
        </div>
      </div>



      <div class="slider">
        <van-slider :disabled="sliderdisabled" v-model="slidervalue" :step="1" :max="4" @change="onChange"
          bar-height="10px" active-color="#DFBEF3">
          <div slot="button" class="custom-button">
            {{ slidervalue }}
          </div>
        </van-slider>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("Withdraw.needpay") }}:</div>

      </div>
      <div class="inputInfo" v-if="slidervalue > 0 && slidervalue >= burnLevel">
        <input v-model.number="daiValue2" type="number" class="inputStyle" style="width: 100%;" disabled />
        <!-- <button @click="daiValue2=formattedDaiOwnMoney3" class="tbnStyle ripple">
        {{ $t("network.all") }}
      </button> -->
      </div>
      <div class="Stakeing" style="width: 100%;margin-top: 20px;" v-show="slidervalue > 0">
        <button style="width: 100%;" class="StakeBtn ripple" @click="Approve()"
          v-if="falconshow == false && typeApprove == 0">
          {{ $t("stake.Appoove") }}
        </button>
        <button style="width: 100%;" class="StakeBtn" v-else-if="typeApprove == 1">
          {{ $t("stake.Appooveing") }}
        </button>
        <button style="width: 100%;height: 65px;" class="StakeBtn ripple" @click="burnDepositToken()"
          v-else-if="typeBurn == 0 && slidervalue >= burnLevel">
          {{ $t("Withdraw.startBurn") }}
        </button>
        <button style="width: 100%;height: 65px;" class="StakeBtn ripple" disabled v-else>
          {{ $t("Withdraw.startBurn") }}
        </button>
      </div>
      <div class="Stakeing" style="width: 100%;margin-top: 20px;" v-show="slidervalue <= 0">

        <button style="width: 100%; height: 65px;" class="StakeBtn ripple" disabled>
          {{ $t("Withdraw.startBurn") }}
        </button>
      </div>
    </div>
    <div><img class="bStyle" :src="stylea" alt="" /></div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg" />
    </div>
    <!--loading end-->
    <div class="selectCoin" v-show="isShown2">
      <div class="tipsandclose">
        <div class="t1">{{ $t("swap.recommend") }}（Polygon）</div>
        <div class="closeimg"><img :src="swap_close" alt="" @click="isShown2 = !isShown2"></div>
      </div>
      <div class="allconin">
        <div class="coinBTN" @click="onselect(swap_usdt, 'USDT', 'USDT_swap', '6')">
          <img :src="swap_usdt" alt="">
          <div class="coiname">USDT</div>
        </div>
        <div class="coinBTN" @click="onselect(swap_usdc, 'USDC', 'USDC_swap', '6')">
          <img :src="swap_usdc" alt="">
          <div class="coiname">USDC</div>
        </div>
        <div class="coinBTN" @click="onselect(swap_dai, 'DAI', 'DAI_swap', '18')">
          <img :src="swap_dai" alt="">
          <div class="coiname">DAI</div>
        </div>
        <!-- <div class="coinBTN" @click="onselect(swap_matic, 'MATIC', 'MATIC', '18')">
                            <img :src="swap_matic" alt="">
                            <div class="coiname">MATIC</div>
                        </div> -->
        <!-- @click="onselect(swap_falcon,'FALCON','falcon_swap','18')" -->
        <!-- <div class="coinBTN" @click="onselect(swap_falcon,'FALCON','falcon_swap','18')">
                            <img :src="swap_falcon" alt="">
                            <div class="coiname">FALCON</div>
                        </div> -->

        <!-- @click="onselect(swap_dai, 'USDS', 'USDC_swap', '18')" -->
        <div class="coinBTN">
          <img :src="swap_dai" alt="">
          <div class="coiname">USDS</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/css/EvWithdraw.css"></style>
<style lang="less" scoped>
.WithDrawBtn {
  display: flex;
  justify-content: space-between;

  .tbnStyle3 {
    width: 134px;
    margin-bottom: 15px;
    height: 50px;
    line-height: 50px;
    position: relative;
    color: rgb(9, 0, 0);
    font-weight: bolder;
    top: 8px;
    border: none;
    outline: none;
    font-size: 20px;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    background-image: linear-gradient(126deg,
        rgba(209, 209, 209, 0.3),
        rgba(208, 208, 208, 0.3));
  }

}

.slider {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-button {
  width: 25px;
  height: 25px;
  line-height: 21px;
  background-color: rgb(172, 7, 114);
  color: #fff;
  border: 2px solid white;
  border-radius: 50px;
  text-align: center;
  font-size: 12px;
}

.tbnStyle2_div {
  background-color: rgb(3, 198, 101);
  color: #fff;
  width: 35px;
  height: 20px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  border-radius: 5px;
  margin-right: 5px;

}

.fanhui {
  display: flex;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 10px;
}

.fanhui img {
  width: 16px;
  height: 16px;
}
</style>
<script>
import swap_falcon from "@/assets/img/swap_falcon.svg";
import swap_close from "@/assets/img/swap_close.svg";
import swap_arrow from "@/assets/img/swap_arrow.svg";
import swap_speed from "@/assets/img/speed.svg";
import swap_crow from "@/assets/img/crow.svg";
import swap_dai from "@/assets/img/swap_dai.svg";
import swap_usdc from "@/assets/img/swap_usdc.svg";
import swap_usdt from "@/assets/img/swap_usdt.svg";
import web3Utils from "@/utils/web3Utils.js";
import Marquee from "@/components/Marquee.vue";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import loadingimg from "@/assets/img/loading.svg";
import siteapi from "@/json/siteapi.json";
import sign from "@/plugins/sign.js";
import Vue from "vue";
import { Slider } from 'vant';
Vue.use(Slider);
import Plugens from "@/plugins/index";
export default {
  name: "EvWithdraw",
  data() {
    return {
      swap_falcon,
      swap_close,
      swap_arrow,
      swap_dai,
      swap_crow,
      swap_speed,
      swap_usdc,
      swap_usdt,
      dai: dai,
      daimni: daimni,
      daiValue: 0,
      daiOwnMoney: 0,
      daiShareMoney: 0,
      daiTemeMoney: 0.0,
      daiTemeMoney2: 0,
      stylea: stylea,
      usersdata: {},
      typeextract: 0,
      typeReinvestment: 0,
      typeReceive: 0,
      loadingimg: loadingimg,
      isloading: false,
      swapimg2: "",
      swapname: "",
      swap_decimalsA: 18,
      isShown2: false,
      swap_Aname: "",
      swap_aimg: "",
      swap_Aaddress: "",
      tokenUsersinvestAmount: 0,
      show: 1,
      slidervalue: 0,
      burnGetMyUserBurnInterestvalue1: 0,
      burnGetMyUserBurnInterestvalue2: 0,
      burnLevel: 0,
      daiOwnMoneyjh: 0,
      falconAmount: 0,
      daiValue2: 0,
      burnContractAddressvalue: "",
      falconshow: false,
      typeApprove: 0,
      typeBurn: 0,
      sliderdisabled: false,
      burnUsersvalue: 0,
      burnAmount: 0

    };
  },
  components: {
    //Marquee,

  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== 'undefined') {
      //console.log("Connected wallet");

      // Asynchronously obtaining network information
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then((chainId) => {
          // Determine whether the current network is a Polygon chain
          if (chainId === '0x89') {
            //console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //console.log("The current network is not Polygon chain");
            this.$router.push({ path: '/' });
            return
          }
        })
        .catch((error) => {
          //console.error('Failed to obtain network information. Procedure:', error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi
    const currentAddress = await web3Utils.connectWallet();
    //console.log(currentAddress)
    this.addresscode = currentAddress
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(currentAddress)
      // );

      // console.log(this.daiOwnMoney.toFixed(6));
      this.signtype()
      // this.exchangeallowance()
    }
  },
  methods: {
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {
          console.log(res, '签名验证')
          if (res.code == 0) {
            this.swapimg2 = this.swapswap_usdt_dai
            this.swapname = 'USDT'
            this.swap_decimalsA = 6;
            this.swap_Aaddress = this.sitedata.USDT_swap.address
            this.swap_aimg = this.swap_usdt
            this.swap_Aname = 'USDT'
            this.users()

          }
          this.isloading = false;
        });
    },
    onChange(e) {
      console.log(this.slidervalue)
      this.burnGetMinFalconToken()
    },
    // User information
    async users() {
      this.isloading = true;
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('User information')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.users(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals
      ).then((res) => {
        this.isloading = false;
        console.log(res, 'User information');
        this.usersdata = res.data
        this.daiOwnMoney = res.data.investAmount * 1
        this.daiTemeMoney = res.data.rewardAmount * 1



        this.getMyUserInterest()
        this.tokenUsers()
        this.burnGetMyUserBurnInterest1()
        this.burnGetMyUserBurnInterest2()
        this.burnUsers()
        this.balance2()
        this.burnContractAddress()
        // this.nowDepositWithdrawToken()
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    // 是否授权
    async exchangeallowance() {
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address;
      spender = this.burnContractAddressvalue
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result, 'falcon_swap是否授权')
        this.isloading = false;
        if (result.data * 1 >= 10000) {
          this.falconshow = true;
        } else {
          this.falconshow = false;
        }

      });
    },
    async Approve() {
      this.isloading = true; // close loading
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.falcon_swap.address;
      spender = this.burnContractAddressvalue
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens(
          Address, //
          this.daiValue, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.falconshow = true;
          } else {
            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {

        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },
    async burnContractAddress() {

      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('User information')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnContractAddress(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals
      ).then((res) => {
        console.log(res, 'burnContractAddress');
        this.burnContractAddressvalue = res.data
        this.exchangeallowance()
      });
    },
    // tokenUsers
    async tokenUsers() {

      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .tokenUsers(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.swap_Aaddress
        )
        .then((res) => {

          console.log(res, 'tokenUsers')
          this.tokenUsersinvestAmount = res.data.investAmount
          this.daiTemeMoney2 = res.data.rewardAmount * 1
          if (this.swap_Aname != 'DAI') {

            if (this.tokenUsersinvestAmount <= 0) {
              this.sliderdisabled = true
            } else {
              this.sliderdisabled = false
            }
          } else {

            if (this.daiOwnMoney <= 0) {

              this.sliderdisabled = true
            } else {

              this.sliderdisabled = false
            }
          }


        });
    },
    async nowDepositWithdrawToken() {
      this.isloading = true;
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .nowDepositWithdrawToken(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.swap_Aaddress
        )
        .then((res) => {
          this.isloading = false;
          console.log(res, 'nowDepositWithdrawToken', this.sitedata)
          var value = res.data.toLowerCase()
          console.log(value, 'valuevaluevaluevalue', this.sitedata.USDC_swap.address)

          this.swap_Aaddress = value
          if (value == this.sitedata.DAI_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_dai
            this.swap_aimg = this.swap_dai
            this.swap_Aname = 'DAI'
            this.swap_decimalsA = 18;
            this.swapname = 'DAI'
          } else if (value == this.sitedata.USDT_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_usdt
            this.swap_aimg = this.swap_usdt
            this.swap_Aname = 'USDT'
            this.swap_decimalsA = 6
            this.swapname = 'USDT'
          } else if (value == this.sitedata.USDC_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_usdc
            this.swap_aimg = this.swap_usdc
            this.swap_Aname = 'USDC'
            this.swap_decimalsA = 6
            this.swapname = 'USDC'
          }

          // this.stakedMoney = res.data.investAmount * 1;
        });
    },
    // 选择币种
    async onselect(img, name, site, _decimals, swaptype) {
      this.swap_decimalsA = _decimals
      this.swap_aimg = img
      this.swap_Aname = name
      this.swap_Aaddress = this.sitedata[site].address
      console.log(this.sitedata[site].address, 'AAAAAAAAAAAAAA')
      //             this.balance()
      // this.exchangeallowance()
      this.isShown2 = false
      this.users()
      this.tokenUsers()
      this.getMyUserInterest()
      this.burnGetMyUserBurnInterest()
      this.burnUsers()
      this.balance2()
      this.burnContractAddress()
    },

    // Personal income
    async getMyUserInterest() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.getMyUserInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress
      ).then((res) => {
        console.log(res, 'getMyUserInterest');
        this.daiShareMoney = res.data * 1
        //console.log(this.daiShareMoney)
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },

    async burnGetMyUserBurnInterest1() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnGetMyUserBurnInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress,
        1
      ).then((res) => {
        console.log(res, 'burnGetMyUserBurnInterest1');
        this.burnGetMyUserBurnInterestvalue1 = res.data
      });
    },
    async burnGetMyUserBurnInterest2() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnGetMyUserBurnInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress,
        2
      ).then((res) => {
        console.log(res, 'burnGetMyUserBurnInterest2');
        this.burnGetMyUserBurnInterestvalue2 = res.data
      });
    },
    async burnUsers() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnUsers(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress
      ).then((res) => {
        console.log(res, 'burnUsers');
        this.burnLevel = res.data.burnLevel
        this.burnAmount = res.data.burnAmount * 1
        this.falconAmount = res.data.falconAmount * 1
        this.burnUsersvalue = (res.data.burnAmount * 1) + (res.data.interestAmount * 1)
        //if (this.burnLevel > 0) {
        this.slidervalue = this.burnLevel
        this.burnGetMinFalconToken()
        //}

      });
    },
    async burnGetMinFalconToken() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.burnGetMinFalconToken(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress,
        this.slidervalue
      ).then((res) => {
        console.log(res, 'burnGetMinFalconToken');
        // this.burnGetMinFalconTokenvalue=res.data[1]
        this.daiValue2 = this.funvalue(res.data[1] * 1.00001)
      });
    },
    funvalue(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    // 激活余额
    async balance2() {
      var that = this
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address
      await Plugens.balanceOf(
        Address,
        0,
        this.addresscode,

        _decimals
      ).then((result) => {
        console.log(result, "falcon余额");
        this.daiOwnMoneyjh = result.data
      });
    },
    //withdraw button
    withdraw() {
      if (this.daiValue <= 0) {

        this.$Notice.warning({
          title: this.$t("Withdraw.WithdrawInfo1")
        });
        return
      }
      // if (this.daiValue > this.daiOwnMoney) {

      //   this.$Notice.warning({
      //     title: this.$t("Withdraw.WithdrawInfo2")
      //   });
      //   return
      // }
      this.isloading = true;
      this.withdrawfun();
      // this.warning(false);
    },

    async burnDepositToken() {
      this.isloading = true;
      let _decimals = 18;
      let Address;
      let falconAmount;
      Address = this.burnContractAddressvalue
      this.typeBurn = 1;
      try {
        if (this.falconAmount - this.daiValue2 > 0) {
          falconAmount = 0;
        } else {
          falconAmount = this.daiValue2 - this.falconAmount;
        }

        falconAmount =
          await contract.burnDepositToken(
            Address, //Contract address
            this.addresscode,//Current address
            _decimals,//Fractional part
            this.sitedata.aave_token.address,
            this.slidervalue,
            falconAmount,
            this.swap_Aaddress,
          ).then((res) => {
            console.log(res);
            this.typeBurn = 0;
            if (res.code == 0) {
              this.typeBurn = 0;
              this.isloading = false;
              this.show = 1
              this.$Notice.success({
                title: this.$t("Withdraw.successful")

              });
              this.getMyUserInterest()
              this.tokenUsers()
              this.burnGetMyUserBurnInterest1()
              this.burnGetMyUserBurnInterest2()
              this.burnUsers()
              this.balance2()
              this.burnContractAddress()

            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("Withdraw.unsuccessfule")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
            }

          });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    // withdraw
    async withdrawfun() {
      let _decimals = this.swap_decimalsA;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeextract = 1;
      try {
        await contract.withdrawToken(
          Address, //Contract address
          this.daiValue, //quantity
          this.addresscode,//Current address
          _decimals,//Fractional part
          this.swap_Aaddress
        ).then((res) => {
          console.log(res);
          this.typeextract = 0;
          if (res.code == 0) {
            this.typeextract = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")

            });
            this.users()

          } else {
            // this.typeApprove = 1;
            this.isloading = false;
            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    withdraw2() {
      this.depositInterestAndReward()
      // var value = this.daiShareMoney + this.daiTemeMoney
      // //console.log(value)
      // if (value >= 0) {
      //   //console.log(123);
      //   this.isloading = true;
      //   this.depositInterestAndReward()
      // } else {
      //   this.$Notice.warning({
      //     title: this.$t("Withdraw.WithdrawInfo2")
      //   });
      // }
      // this.warning2(false);
    },
    // reinvestment
    async depositInterestAndReward() {
      //console.log("reinvestment")
      this.isloading = true;
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeReinvestment = 1;
      try {
        await contract.depositInterestAndReward(
          Address, //Contract address
          this.daiValue, //quantity
          this.addresscode,//Current address
          _decimals,//float
          this.swap_Aaddress
        ).then((res) => {
          console.log(res);
          this.typeReinvestment = 0;
          if (res.code == 0) {
            this.typeReinvestment = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()

          } else {
            this.isloading = false;

            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }


          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    withdraw3() {
      this.withdrawInterestAndRewardToken()
      // var value = this.daiShareMoney + this.daiTemeMoney
      // //console.log(value)
      // if (value >= 0) {
      //   this.isloading = true;
      //   this.withdrawInterestAndRewardToken()
      // } else {
      //   this.$Notice.warning({
      //     title: this.$t("Withdraw.WithdrawInfo2")
      //   });
      // }
    },
    // withdraw
    async withdrawInterestAndRewardToken() {
      this.isloading = true;
      //console.log("withdraw")
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeReceive = 1;
      try {
        await contract.withdrawInterestAndRewardToken(
          Address, //Contract address
          this.daiValue, //number
          this.addresscode,//current address
          _decimals,//float
          this.swap_Aaddress
        ).then((res) => {
          //console.log(res);
          this.typeReceive = 0;
          if (res.code == 0) {
            this.typeReceive = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()

          } else {
            // this.typeApprove = 1;
            this.isloading = false;

            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    warning(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.withdraw"),
      });
    },
    warning2(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Reinvest"),
      });
    },
    warning3(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Getreward"),
      });
    },
  },

  computed: {
    formattedDaiOwnMoney2() {
      if (!this.tokenUsersinvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersinvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.00";
      const multipliedNumber = this.daiOwnMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      //console.log(this.daiOwnMoney)
      let value = parseFloat(result);
      return value
    },
    formattedShareMoney() {
      if (!this.daiShareMoney) return "0.00";
      const multipliedNumber = this.daiShareMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedTemeMoney() {
      if (!this.daiTemeMoney) return "0.00";
      const multipliedNumber = this.daiTemeMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedTemeMoney2() {
      if (!this.daiTemeMoney2) return "0.00";
      const multipliedNumber = this.daiTemeMoney2 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      let value = parseFloat(result);
      return value
    },
    formattedDaiOwnMoney3() {
      if (!this.daiOwnMoneyjh) return "0.0000";
      const multipliedNumber = this.daiOwnMoneyjh * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;

      let value = parseFloat(result);
      return value
    },
    burnFalconAmount() {
      if (!this.falconAmount) return "0.0000";
      const multipliedNumber = this.falconAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      let value = parseFloat(result);
      return value
    },


    formattedDaiOwnMoney4() {
      if (!this.burnGetMyUserBurnInterestvalue1) return "0.0000";

      const multipliedNumber = this.burnGetMyUserBurnInterestvalue1 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;

      let value = parseFloat(result);


      return value
    },
    formattedDaiOwnMoney5() {
      if (!this.burnGetMyUserBurnInterestvalue2) return "0.0000";

      const multipliedNumber = this.burnGetMyUserBurnInterestvalue2 * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;

      let value = parseFloat(result);


      return value
    },

  },
};
</script>
